import './App.css';
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';

const App = () => {
  const menuItems = [
    { name: "कृषी", icon: "bi-flower1" },
    { name: "कपडे", icon: "bi-bag" },
    { name: "खरेदी / विक्री", icon: "bi-currency-exchange" },
    { name: "खाद्यपदार्थ", icon: "bi-egg-fried" },
    { name: "बँका", icon: "bi-bank" },
    { name: "दुकाने", icon: "bi-shop" },
    { name: "हार्डवेयर", icon: "bi-tools" },
    { name: "इलेक्ट्रॉनिक्स", icon: "bi-tv" },
    { name: "हॉस्पिटल", icon: "bi-hospital" },
    { name: "होटेल", icon: "bi-cup-hot" },
    { name: "शाळा", icon: "bi-mortarboard" },
    { name: "कॉलेज", icon: "bi-building" },
    { name: "चपलांचे दुकान", icon: "bi-bootstrap-reboot" },
    { name: "फर्निचर दुकाने", icon: "bi-door-closed" },
    { name: "ब्युटी पार्लर", icon: "bi-brush" },
    { name: "लग्न लॉन्स", icon: "bi-balloon" }
  ];

  return (
    <div>
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-success sticky-top">
        <div className="container-fluid">
          <a className="navbar-brand fw-bold" href="#">आपले लासलगाव</a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto flex-nowrap overflow-auto">
              {menuItems.map((item, index) => (
                <li className="nav-item" key={index}>
                  <a
                    className="nav-link d-flex align-items-center gap-1 text-white px-3"
                    href={`#${item.name}`}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <i className={`bi ${item.icon}`}></i> {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="container mt-4">
        <h1 className="text-center text-success">
          लवकरच लासलगावकरांसाठी एक खास डिजिटल प्लॅटफॉर्म – आपल्या गावातील प्रत्येक सेवा, दुकान, संस्था आणि व्यवसाय आता एका क्लिकवर उपलब्ध.
          स्थानिकांसाठी स्थानिकांनीच बनवलेलं! विश्वास, सुविधा आणि प्रगती यांचं नवं दालन लवकरच आपल्या सेवेत.
        </h1>
      </div>

      {/* Sections for Each Menu Item */}
      {menuItems.map((item, index) => (
        <section key={index} id={item.name} className="container my-5">
          <h2 className="text-center text-primary"><i className={`bi ${item.icon}`}></i> {item.name}</h2>
          <p className="text-center">लवकरच {item.name} विषयी माहिती येथे पाहायला मिळेल.</p>
        </section>
      ))}

      {/* Footer */}
      <footer className="bg-success text-white text-center py-3">
        © 2025 Aaple Lasalgaon. All rights reserved. Developed by TechKritika Software LLP.
      </footer>
    </div>
  );
};

export default App;
