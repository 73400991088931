
// firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCe4czl46jYDnTqSbQvsU8vSKyk4d-oB2U",
    authDomain: "lasalgaon-90a16.firebaseapp.com",
    projectId: "lasalgaon-90a16",
    storageBucket: "lasalgaon-90a16.appspot.com", // ✅ corrected!
    messagingSenderId: "747403967039",
    appId: "1:747403967039:web:81f89f54402e17a8b8568a",
    measurementId: "G-MC2KWZ0CCT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };